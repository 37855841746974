.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  transition: 0.2s;
}
.swiper-button-prev {
  padding-right: 5px;
}
.swiper-button-next {
  padding-left: 5px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 28px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
