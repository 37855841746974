hr.happy-birthday__sharp-line {
  display: block;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  margin: 0 auto;
  width: 300px;
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(0, 92, 255, 0.9), rgba(255, 0, 0, 0));
}

hr.happy-birthday__sharp-white-line {
  display: block;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  margin: 0 auto;
  /* width: 300px; */
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, rgba(97, 97, 97, 0), rgba(255, 255, 255, 0.90), rgba(97, 97, 97, 0));
}

.happy-birthday__container{
  max-width: 400px;
  max-height: 361px;
  background-color: #fff;
  text-align: center;
}

.happy-birthday__button{
  display: block;
  text-decoration: none !important;
  background-color: #317BFF;
  border-radius: 12px;
  max-width: 340px;
  max-height: 50px;
  padding: 16px 134px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  color: white !important;
}

.happy-birthday__title{
  font-size: 24px;
  font-weight: 900;
  color: #317BFF;
  font-family: 'Inter', sans-serif;
}
