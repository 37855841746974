.help-button {
  background-color: #317bff;
  border-radius: 100%;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  width: 26px;
  height: 26px;
  font-size: 16px;
  padding-top: 2px;
  text-align: center;
  text-decoration: none;
}

.signin__button {
  display: flex;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #15519d;
  color: #fff;
  line-height: normal;
}

.signin__button:hover {
  background-color: #275897;
}

.signin__language-selector {
  border: 1px solid #15519d;
  padding: 5px;
  margin-bottom: 40px;
  height: 54px;
  border-radius: 10px;
}
