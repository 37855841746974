.label {
  width: 55px;
  height: 25px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}

.label::after {
  content: '';
  width: 24px;
  height: 23px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.input:checked + .label {
  background: #242424;
}

.input:checked + .label:after {
  left: 30px;
  background: linear-gradient(180deg, #777, #3a3a3a);
}

.label:active::after {
  width: 32px;
}

.label svg {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 2px;
  z-index: 100;
}

.label svg.sun {
  left: 2px;
  fill: #fff;
  transition: 0.3s;
}

.label svg.moon {
  left: 32px;
  fill: #7e7e7e;
  transition: 0.3s;
}

.input:checked + .label svg.sun {
  fill: #7e7e7e;
}

.input:checked + .label svg.moon {
  fill: #fff;
}
