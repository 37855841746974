/**
 * Tailwind's utility classes, generated based on your config file.
 */

@tailwind utilities;

@font-face {
  font-family: 'Lexend-Medium';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Medium';
  src: url('../../public/assets/fonts/lexend/Lexend-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend-Bold';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Bold';
  src: url('../../public/assets/fonts/lexend/Lexend-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend-ExtraBold';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'ExtraBold';
  src: url('../../public/assets/fonts/lexend/Lexend-ExtraBold.ttf') format('truetype');
}