.linear-gradient {
  background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, rgba(0, 0, 0, .8));
  background-size: cover;
  background-position: top;
}

.perspective {
  perspective: 1000px;
}

.scale-in-img {
  transform: translateZ(20px);
  /* transition: transform .4s ease-in; */
}



